import React, { Fragment } from "react"
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Atrilon from "../../Assets/Atrilon-logo.jpg";

import './header.scss';

const Header = () => {
    return (
        <Fragment>
            <Navbar className="navbar-font" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="/" className="logo">
                        <img src={Atrilon} alt="Atrilon Technologies Private Limited" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end mobile-view-line">
                        <Nav className="ms-auto">
                            <Nav.Link as={NavLink} to="/" end>HOME</Nav.Link>
                            <Nav.Link as={NavLink} to="about">ABOUT</Nav.Link>
                            <Nav.Link as={NavLink} to="services">SERVICES</Nav.Link>
                            <Nav.Link as={NavLink} to="career">CAREER</Nav.Link>
                            <Nav.Link as={NavLink} to="https://blogs.atrilon.com/">BLOGS</Nav.Link>
                            <Nav.Link as={NavLink} to="contact">CONTACT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    )
}

export default Header


