import React from "react";
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { BiChevronRight, BiCopyright, BiLogoLinkedin } from "react-icons/bi";

import "./footer.scss";

const Footer = () => {
    return (
        <Container fluid className="footer common-quote-button">
            <div className="footer-section-1">
                <Row>
                    <Col sm={12} md={4}>
                        <h4>ATRILON TECHNOLIGIES PVT LTD</h4>
                        <p>
                            1207/343 & 1207/1/343/1, 9th Main, 7th Sector, <br />
                            HSR Layout, Bangalore, <br />
                            KA 560102 IN
                        </p>
                        <p>
                            Email: <a href="mailto:info@atrilon.com">info@atrilon.com</a>
                        </p>
                        <p>
                            Phone: +91 70226 77358
                        </p>
                    </Col>
                    <Col sm={12} md={2}>
                        <h5>Useful Links</h5>
                        <Nav className="flex-column footer-nav">
                            <Nav.Link href="/"><BiChevronRight className="right-arrow-icon" /> Home</Nav.Link>
                            <Nav.Link href="about"><BiChevronRight className="right-arrow-icon" /> About</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Services</Nav.Link>
                            <Nav.Link href="career"><BiChevronRight className="right-arrow-icon" /> Career</Nav.Link>
                            <Nav.Link href="https://blogs.atrilon.com/"><BiChevronRight className="right-arrow-icon" /> Blogs</Nav.Link>
                            <Nav.Link href="contact"><BiChevronRight className="right-arrow-icon" /> Contact</Nav.Link>
                        </Nav>
                    </Col>
                    <Col sm={12} md={4}>
                        <h5>Services</h5>
                        <Nav defaultActiveKey="/" className="flex-column footer-nav">
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Product Design and Development</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Engineering Analysis</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Rotor Dyanamics and Bearing Analysis</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Prototyping and Testing</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Customized Web Apps for Calculations</Nav.Link>
                            <Nav.Link href="services"><BiChevronRight className="right-arrow-icon" /> Training and Technical Support</Nav.Link>
                        </Nav>
                    </Col>
                    <Col sm={12} md={2} className="quote">
                        <h5>Connect with Us</h5>
                        <Button href="/contact" className="btn-quote">Request Quote</Button>
                    </Col>
                </Row>
            </div>
            <div className="footer-section-2">
                <Row>
                    <Col sm={12} md={6}>
                        <p>
                            <BiCopyright /> Copyright <span>Atrilon Technologies Pvt Ltd</span>. All Rights Reserved.
                        </p>
                    </Col>
                    <Col sm={12} md={6} className="ms-auto social">
                        {/* <a href="/" className="social-icon">
                            <BiLogoTwitter  />
                        </a> */}
                        <a href="https://www.linkedin.com/company/atrilon-technologies-pvt-ltd/" target="_blank" rel="noreferrer" className="social-icon">
                            <BiLogoLinkedin />
                        </a>
                        {/* <a href="/" className="social-icon">
                            <BiLogoFacebook />
                        </a> */}
                    </Col>
                </Row>
            </div>
           
        </Container>
    )
}

export default Footer
